import { useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, Stack, Button, Link } from './ClinicDetail.styled';
import ModalEditClinicMemo from '~/components/routes/Clinic/ModalEditClinicMemo';
import { toThousandCommas } from '~/filters';
import ModalUsedAccount from './ModalUsedAccount';
import CtiSenderSection from './CtiSenderSection';
import { useApp } from '~/hooks/useApp';
import { banks, agencies } from '~/hooks/constants';
import { format, isValid } from 'date-fns';
import ResetPasswordModal from '~/components/routes/ClinicDetail/ResetPasswordModal';
import { Tooltip } from '~/components/commons/Tooltip';
import { useImperativeModal } from '~/components/providers/ImperativeModalProvider';

type ClinicReadViewProps = {
  data: any;
};

const ClinicReadView = (props: ClinicReadViewProps) => {
  const app = useApp();
  const { data } = props;
  const [showDeactivateReasonModal, setShowDeactivateReasonModal] =
    useState<any>(false);
  const imperativeModal = useImperativeModal();

  const onClickMemo = (memo: any, idx: number, id: number, item: any) => {
    onAddMemo(memo, idx, id, item);
  };

  const onAddMemo = (memo: any, memoIndex: number, id: number, item: any) => {
    setShowDeactivateReasonModal({
      memo,
      memoIndex,
      id,
      item,
    });
  };

  const handleOpenUsedAccountModal = async () => {
    await imperativeModal.open((close) => {
      return (
        <ModalUsedAccount
          clinicId={data.id}
          title={'사용중인 계정'}
          onClose={close}
        />
      );
    });
  };

  const onClickResetPassword = async (id: number) => {
    app.$modal.custom({
      component: ResetPasswordModal,
      options: { id },
    });
  };

  const penchartPlan =
    data.billingPlan?.penchartPlanPrice &&
    !isNaN(Number(data.billingPlan?.penchartPlanPrice))
      ? Number(data.billingPlan?.penchartPlanPrice)
      : 0;
  const crmManagementPlan =
    data.billingPlan?.crmManagementPlanPrice &&
    !isNaN(Number(data.billingPlan?.crmManagementPlanPrice))
      ? Number(data.billingPlan?.crmManagementPlanPrice)
      : 0;
  const signCertPlan =
    data.billingPlan?.signCertPlanPrice &&
    !isNaN(Number(data.billingPlan?.signCertPlanPrice))
      ? Number(data.billingPlan?.signCertPlanPrice)
      : 0;
  const number080Plan =
    data.billingPlan?.number080PlanPrice &&
    !isNaN(Number(data.billingPlan?.number080PlanPrice))
      ? Number(data.billingPlan?.number080PlanPrice)
      : 0;
  const insurancePlan =
    data.billingPlan?.insurancePlanPrice &&
    !isNaN(Number(data.billingPlan?.insurancePlanPrice))
      ? Number(data.billingPlan?.insurancePlanPrice)
      : 0;

  let treatmentFields = [];
  try {
    treatmentFields =
      data.treatmentFields !== '[]' || data.treatmentFields !== ''
        ? JSON.parse(data.treatmentFields || null) || []
        : [];
  } catch (error) {
    console.error('Parsing errors: ', error);
    treatmentFields = [];
  }

  let memo = [];
  try {
    memo =
      data?.memo !== undefined &&
      data?.memo !== '' &&
      data?.memo !== null &&
      data?.memo !== '{}' &&
      data?.memo !== '[]'
        ? JSON.parse(data.memo || null) || []
        : [];
  } catch (error) {
    console.error('Parsing errors: ', error);
    memo = [];
  }

  const agency =
    agencies.filter((o) => {
      return o.value === data?.additional?.agency;
    })?.[0]?.key || '없음';

  const educationAgency =
    agencies.filter((o) => {
      return o.value === data?.additional?.educationAgency;
    })?.[0]?.key || '없음';

  return (
    <>
      <Typography variant="h2">계정 정보</Typography>
      <Stack flexDirection={'row'} className="mt-13 border">
        <Box className="label">계약상태</Box>
        <Box className="value w-220">
          {data?.status === 'active'
            ? '사용중'
            : data?.status === 'inactive'
            ? '해지'
            : '-'}
        </Box>
        <Box className="label">계정유형</Box>
        <Box className="value">
          {data?.test === true
            ? '테스트'
            : data?.test === false
            ? '병원계정'
            : '-'}
        </Box>
      </Stack>
      <Stack flexDirection={'column'} className="mt-10 border">
        <Stack className="" flexDirection={'row'}>
          <Box className="label">아이디(이메일)</Box>
          <Box className={`value w-220`}>
            <span className="multi-line">{data?.contactEmail || '-'}</span>
          </Box>
          <Box className="label">비밀번호</Box>
          <Box className="value">
            <Tooltip
              title={app.$me.role === 'manager' ? '권한이 없습니다.' : ''}
            >
              <button
                className="btn btn-primary btn-sm cursor-pointer"
                onClick={() => onClickResetPassword(data?.owner?.id)}
                disabled={app.$me.role === 'manager'}
              >
                비밀번호 초기화
              </button>
            </Tooltip>
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">담당자 이름</Box>
          <Box className="value w-220">{data?.contactManager || '-'}</Box>
          <Box className="label">전화번호</Box>
          <Box className="value">{data?.contactTel || '-'}</Box>
        </Stack>
      </Stack>
      <Typography variant="h2">사업자 정보</Typography>
      <Stack flexDirection={'column'} className="mt-10 border">
        <Stack className="" flexDirection={'row'}>
          <Box className="label">거래처명(병원명)</Box>
          <Box className="value">{data?.name || '-'}</Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">구 거래처명</Box>
          <Box className="value">{data?.oldName || '-'}</Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">대표자</Box>
          <Box className="value w-220">{data?.ownerName || '-'}</Box>
          <Box className="label">대표번호</Box>
          <Box className="value">{data?.clinicTel || '-'}</Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">사업자등록번호</Box>
          <Box className="value w-220">{data?.employerNo || '-'}</Box>
          <Box className="label">요양기관번호</Box>
          <Box className="value">{data?.clinicNo || '-'}</Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">주소</Box>
          <Box className="value w-220">
            <span className="multi-line">{data?.address || '-'}</span>
          </Box>
          <Box className="label">상세 주소</Box>
          <Box className="value">{data?.addressDetail || '-'}</Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">구분</Box>
          <Box className="value w-220">{data?.category || '-'}</Box>
          <Box className="label">주요 시/수술 분야</Box>
          <Box className="value">{treatmentFields.join(', ') || '-'}</Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">업태</Box>
          <Box className="value w-220">{data?.bizStatus || '-'}</Box>
          <Box className="label">업종</Box>
          <Box className="value">{data?.bizItem || '-'}</Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">사업자등록증</Box>
          <Box className="value">
            {data?.bizLicenseUrl ? (
              <Link href={data.bizLicenseUrl} target="_blank">
                {data.bizLicenseUrl}
              </Link>
            ) : (
              '-'
            )}
          </Box>
        </Stack>
      </Stack>
      <Typography variant="h2">CMS 자동이체 정보</Typography>
      <Stack flexDirection={'column'} className="mt-10 border">
        <Stack className="" flexDirection={'row'}>
          <Box className="label">은행</Box>
          <Box className="value">
            {banks.find((item) => item.value === data?.cms?.bank)?.key || '-'}
          </Box>
          <Box className="label">계좌번호</Box>
          <Box className="value">{data?.cms?.bankAccount || '-'}</Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">자동이체(매월)</Box>
          <Box className="value">{data?.cms?.autoTransferDay || '-'}</Box>
          <Box className="label">세금계산서 발행메일</Box>
          <Box className="value">{data?.cms?.taxbillEmail || '-'}</Box>
        </Stack>
      </Stack>
      <Typography variant="h2">대행사 및 기타 정보</Typography>
      <Stack flexDirection={'column'} className="mt-10 border">
        <Stack className="" flexDirection={'row'}>
          <Box className="label">계약 대행사</Box>
          <Box className="value">{agency}</Box>
          <Box className="label">교육 대행사</Box>
          <Box className="value">{educationAgency}</Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">자동 차트번호 타입</Box>
          <Box className="value">{data?.additional?.chartNoType || '-'}</Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">센트릭스 사용여부</Box>
          <Box className="value">
            {data?.callManagement?.type !== 0 ? '사용' : '미사용'}
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">회선정보</Box>
          <Box className="value">
            {data?.centrexAccounts
              ? data.centrexAccounts.map((item: any, id: number) => (
                  <span className="centrex-wrapper" key={id}>
                    {`${item?.carrier || '-'} / ${
                      item?.accountId || '-'
                    } / ${'*'.repeat(item?.accountPassword?.length || 0)}`}
                  </span>
                ))
              : '-'}
          </Box>
        </Stack>
      </Stack>
      <Typography variant="h2">월 관리비 정보(VAT별도)</Typography>
      <Stack flexDirection={'column'} className="mt-10 border">
        <Stack className="" flexDirection={'row'}>
          <Box className="label">CRM 운영비</Box>
          <Box className="value">
            {toThousandCommas(String(crmManagementPlan))}
          </Box>
          <Box className="label">사용자 계정 수</Box>
          <Box className="value">
            {toThousandCommas(data?.billingPlan?.crmManagementPlanLimit || '-')}
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">펜차트 관리비</Box>
          <Box className="value">{toThousandCommas(String(penchartPlan))}</Box>
          <Box className="label">펜차트 용량(GB)</Box>
          <Box className="value">
            {toThousandCommas(data?.billingPlan?.penchartPlanLimit || '-')}
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">전자서명</Box>
          <Box className="value">{toThousandCommas(String(signCertPlan))}</Box>
          <Box className="label">전자서명 사용 수</Box>
          <Box className="value">
            {toThousandCommas(data?.billingPlan?.signCertPlanLimit || '-')}
          </Box>
        </Stack>

        <Stack className="" flexDirection={'row'}>
          <Box className="label">080 번호사용</Box>
          <Box className="value">{toThousandCommas(String(number080Plan))}</Box>
          <Box className="label">080 수신거부 번호</Box>
          <Box className="value">{data?.rejectionPhoneNumber || '-'}</Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">보험청구 연동여부</Box>
          <Box className="value">
            {data?.isUberSync === true ? '연동' : '미연동'}
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">보험청구</Box>
          <Box className="value">{toThousandCommas(String(insurancePlan))}</Box>
          <Box className="label">보험청구 사용 수</Box>
          <Box className="value">
            {toThousandCommas(data?.billingPlan?.insurancePlanLimit || '-')}
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">총 금액</Box>
          <Box className="value">
            {toThousandCommas(
              String(
                penchartPlan +
                  crmManagementPlan +
                  signCertPlan +
                  number080Plan +
                  insurancePlan
              )
            )}
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">최초 계약일</Box>
          <Box className="value">
            {data.contractDate
              ? format(new Date(data.contractDate), 'yyyy/MM/dd')
              : '-'}
          </Box>
        </Stack>
        {data?.contracts ? (
          data?.contracts.length > 0 ? (
            data?.contracts.map((item: any, index: number) => {
              return (
                <Stack className="" flexDirection={'row'} key={index}>
                  <Box className="label">계약서</Box>
                  <Box className="value">
                    <div className="contracts-wrapper" key={index}>
                      <div>
                        <Link href={item.url} target="_blank">
                          <Tooltip title={item.url}>
                            <div>{item.url}</div>
                          </Tooltip>
                        </Link>
                      </div>
                    </div>
                  </Box>
                  <Box className="label">계약서 작성일자</Box>
                  <Box className="value">{item.contractSignDate || '-'}</Box>
                </Stack>
              );
            })
          ) : (
            <Stack className="" flexDirection={'row'}>
              <Box className="label">계약서</Box>
              <Box className="value">
                <div className="contracts-wrapper">-</div>
              </Box>
              <Box className="label">계약서 작성일자</Box>
              <Box className="value">-</Box>
            </Stack>
          )
        ) : (
          ''
        )}
      </Stack>
      <Typography variant="h2">메모</Typography>
      <Stack className="border" flexDirection={'row'}>
        <Box className="label">메모</Box>
        <Box className="value">
          <Box className={`memo`}>
            <div className="memo-wrapper">
              {memo.map((memoItem: any, idx: number) => {
                return (
                  <div
                    onClick={() => onClickMemo(data.memo, idx, data.id, data)}
                    className="td-underline single-memo"
                    key={idx}
                    style={{ cursor: 'pointer' }}
                  >
                    {memoItem.content} ({memoItem.timestamp})
                  </div>
                );
              })}
            </div>
            <Box className="memo-btn">
              <Button
                onClick={() => onAddMemo(data.memo, -1, data.id, data)}
                className="btn btn-info"
                variant="contained"
              >
                메모 추가
              </Button>
            </Box>
          </Box>
        </Box>
      </Stack>
      <Typography variant="h2">사용현황</Typography>
      <Stack flexDirection={'column'} className="mt-10 border">
        <Stack className="" flexDirection={'row'}>
          <Box className="label">전자서명</Box>
          <Box className="value">
            {toThousandCommas(String(data?.certificationUserCount || 0))}
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">간편접수</Box>
          <Box className="value">
            {toThousandCommas(String(data?.registrationUserCount || 0))}
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">계정수(사용중)</Box>
          <Box className="value">
            {data?.userCount ? (
              <span className="user-count" onClick={handleOpenUsedAccountModal}>
                {toThousandCommas(String(data?.userCount || 0))}
              </span>
            ) : (
              '-'
            )}
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">펜차트 용량(GB)</Box>
          <Box className="value">
            {toThousandCommas(String(data?.s3Size || 0))}
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">문자 잔액(원)</Box>
          <Box className="value">
            {toThousandCommas(
              String(
                (!isNaN(Number(data?.balance?.amount))
                  ? Number(data?.balance?.amount)
                  : 0) +
                  (!isNaN(Number(data?.balance?.freeAmount))
                    ? Number(data?.balance?.freeAmount)
                    : 0)
              )
            )}
          </Box>
        </Stack>
        <Stack className="" flexDirection={'row'}>
          <Box className="label">최근접속일시</Box>
          <Box className="value">
            {isValid(new Date(data?.customerConvertType))
              ? format(
                  new Date(data?.customerConvertType),
                  'yyyy-MM-dd hh:MM:ss'
                )
              : '-'}
          </Box>
        </Stack>
      </Stack>
      {data.id && <CtiSenderSection clinicId={data.id} />}
      {showDeactivateReasonModal !== false && (
        <ModalEditClinicMemo
          info={showDeactivateReasonModal}
          title={'CS메모'}
          onClose={() => setShowDeactivateReasonModal(false)}
        />
      )}
    </>
  );
};
export default observer(ClinicReadView);
