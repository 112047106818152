import { color } from '~/theme';
import { FilterDateRange } from '~/components/commons/SearchFilterDateRange';
import { useCallback, useMemo } from 'react';
import {
  Button,
  TextField,
  Box,
  Typography,
  SearchFilterRangeInput,
  DropdownList,
} from './SearchView.styled';
import { agencies as agenciesOptions } from '~/hooks/constants';
import { stripEmpty } from '~/utils/objUtil';
import { format } from 'date-fns';

const contractStatusOptions = [
  { label: '사용중', value: 'active', id: 'active' },
  { label: '해지', value: 'inactive', id: 'inactive' },
];

const accountTypesOptions = [
  { label: '테스트 계정', value: 'true', id: 'true' },
  { label: '병원 계정', value: 'false', id: 'false' },
];

const carrierTypesOptions = [
  { label: '없음', value: 'null', id: 'null' },
  { label: 'KT', value: 'KT', id: 'KT' },
  { label: 'LG', value: 'LG', id: 'LG' },
  { label: 'SK', value: 'SK', id: 'SK' },
];

type SearchViewProps = {
  width: number;
  onSubmit: () => void;
  onChange: (params: any) => void;
  value: any;
  onSearch: (params: any) => void;
};

export const SearchView = ({
  onSearch,
  value,
  onChange,
  onSubmit,
  width,
}: SearchViewProps) => {
  const searchFilterValues = useMemo(() => {
    return stripEmpty(
      {
        ...value,
        name: value?.name?.length < 2 ? '' : value.name,
        clinicTel: value?.clinicTel?.length < 4 ? '' : value.clinicTel,
        contactTel: value?.contactTel?.length < 4 ? '' : value.contactTel,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
  }, [value]);

  const disabled = useMemo(() => {
    return Object.keys(searchFilterValues).length <= 3;
  }, [searchFilterValues]);

  const handleEnter = useCallback(() => {
    !disabled && onSubmit();
  }, [disabled, searchFilterValues]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: width ?? undefined,
        maxWidth: 1800,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          background: 'white',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: `1px solid ${color.common.line}`,
            '& > *:not(:last-child)': {
              borderRight: `1px solid ${color.common.line}`,
            },
          }}
        >
          <Box width={380}>
            <FilterDateRange
              label="등록일"
              startAt={value.createdAtStart}
              endAt={value.createdAtEnd}
              placeholder={'YYYY-MM-DD'}
              onChange={(start: string, end: string) => {
                onChange({
                  createdAtStart: start
                    ? format(new Date(start), 'yyyy-MM-dd')
                    : undefined,
                  createdAtEnd: end
                    ? format(new Date(end), 'yyyy-MM-dd')
                    : undefined,
                });
              }}
            />
          </Box>
          <Box width={250}>
            <TextField
              $type="search"
              value={value.id === 0 ? '' : String(value.id)}
              startAdornment="ID"
              placeholder="입력"
              onChange={(v: any) => {
                const newId = !isNaN(Number(v))
                  ? v
                  : value.id !== ''
                  ? value.id
                  : '';
                onChange({ id: newId });
              }}
              onEnter={handleEnter}
            />
          </Box>
          <Box width={250}>
            <TextField
              $type="search"
              value={value.name}
              startAdornment="거래처명"
              placeholder="2자리 이상 입력"
              onChange={(v: any) => onChange({ name: v })}
              onEnter={handleEnter}
            />
          </Box>
          <Box width={250}>
            <TextField
              $type="search"
              value={value.oldName}
              startAdornment="구 거래처명"
              placeholder="입력"
              onChange={(v: any) => onChange({ oldName: v })}
              onEnter={handleEnter}
            />
          </Box>
          <Box width={250}>
            <TextField
              $type="search"
              value={value.employerNo}
              startAdornment="사업자번호"
              placeholder="입력"
              onChange={(v: any) => onChange({ employerNo: v })}
              onEnter={handleEnter}
            />
          </Box>
          <Box width={250}>
            <TextField
              $type="search"
              value={value.contactName}
              startAdornment="담당자"
              placeholder="입력"
              onChange={(v: any) => onChange({ contactName: v })}
              onEnter={handleEnter}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: 1 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            background: 'white',
            border: `1px solid ${color.common.line}`,
            '& > *:not(:last-child)': {
              borderRight: `1px solid ${color.common.line}`,
            },
          }}
        >
          <Box width={250}>
            <TextField
              $type="search"
              value={value.contactTel}
              startAdornment="전화번호"
              placeholder="뒷자리 4자리"
              onChange={(v: any) => onChange({ contactTel: v })}
              onEnter={handleEnter}
            />
          </Box>
          <Box width={250}>
            <TextField
              $type="search"
              value={value.clinicTel}
              startAdornment="대표번호"
              placeholder="뒷자리 4자리"
              onChange={(v: any) => onChange({ clinicTel: v })}
              onEnter={handleEnter}
            />
          </Box>
          <Box
            sx={{
              width: 250,
              background: 'white',
            }}
          >
            <DropdownList
              value={contractStatusOptions.filter((o) => value.status === o.id)}
              options={contractStatusOptions}
              onChange={(v: any) => {
                onChange({ status: v?.id || '' });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              startAdornment={<Typography>{'계약상태'}</Typography>}
              placeholder="전체"
            />
          </Box>
          <Box width={380}>
            <FilterDateRange
              label="최초 계약일"
              startAt={value.contractDateStart}
              endAt={value.contractDateEnd}
              placeholder={'YYYY-MM-DD'}
              onChange={(start: string, end: string) => {
                onChange({
                  contractDateStart: start
                    ? format(new Date(start), 'yyyy-MM-dd')
                    : undefined,
                  contractDateEnd: end
                    ? format(new Date(end), 'yyyy-MM-dd')
                    : undefined,
                });
              }}
            />
          </Box>
          <Box
            sx={{
              width: 250,
              background: 'white',
            }}
          >
            <DropdownList
              value={accountTypesOptions.filter((o) =>
                value.test?.includes(o.id)
              )}
              options={accountTypesOptions}
              onChange={(v: any) => {
                onChange({ test: v?.id || '' });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              startAdornment={<Typography>{'계정유형'}</Typography>}
              placeholder="전체"
            />
          </Box>
          <Box
            sx={{
              width: 250,
              background: 'white',
            }}
          >
            <DropdownList
              value={agenciesOptions.filter((o) =>
                value.agency?.includes(o.value)
              )}
              options={agenciesOptions.map((item: any) => {
                item.label = item.key;
                item.id = item.value;
                return item;
              })}
              onChange={(v: any) => {
                onChange({ agency: v?.value || '' });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              startAdornment={
                <Typography className="width-wide">{'계약대행사'}</Typography>
              }
              placeholder="전체"
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: 1 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            background: 'white',
            border: `1px solid ${color.common.line}`,
            '& > *:not(:last-child)': {
              borderRight: `1px solid ${color.common.line}`,
            },
          }}
        >
          <Box
            sx={{
              width: 250,
            }}
          >
            <DropdownList
              value={carrierTypesOptions.filter((o) => value.carrier === o.id)}
              options={carrierTypesOptions}
              onChange={(v: any) => {
                onChange({ carrier: v?.id || '' });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              startAdornment={<Typography>{'센트릭스'}</Typography>}
              placeholder="전체"
            />
          </Box>
          <Box
            sx={{
              width: 320,
              background: 'white',
            }}
          >
            <SearchFilterRangeInput
              className="account-count"
              label="계약 계정 수"
              placeholder={'n'}
              endAdornment="개"
              startValue={value.userCountMin}
              endValue={value.userCountMax}
              onChange={(start: string, end: string) => {
                const userCountMin =
                  start && !isNaN(Number(start)) ? Number(start) : '';
                const userCountMax =
                  end && !isNaN(Number(end)) ? Number(end) : '';
                onChange({
                  userCountMin,
                  userCountMax,
                });
              }}
            />
          </Box>
          <Box
            sx={{
              width: 360,
              background: 'white',
            }}
          >
            <SearchFilterRangeInput
              className="pen-chart-account-bucket-size"
              label="펜차트 계약 용량"
              placeholder={'n'}
              endAdornment="GB"
              startValue={value.s3SizeMin}
              endValue={value.s3SizeMax}
              onChange={(start: string, end: string) => {
                const s3SizeMin =
                  start && !isNaN(Number(start)) ? Number(start) : '';
                const s3SizeMax = end && !isNaN(Number(end)) ? Number(end) : '';
                onChange({
                  s3SizeMin,
                  s3SizeMax,
                });
              }}
            />
          </Box>
        </Box>
        <Button
          styled="outline"
          onClick={onSearch}
          className="search-btn"
          disabled={disabled}
        >
          조회
        </Button>
      </Box>
    </Box>
  );
};
