import { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Stack, Button } from './EditView.styled';
import update from 'immutability-helper';
import { useApp } from '~/hooks/useApp';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import styled from 'styled-components';
import { objectToFormData } from 'object-to-formdata';

const FlexRow = styled.div`
  display: flex;
  column-gap: 5px;
  padding-bottom: 5px;

  &.label {
    margin-top: 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
  }
`;

const Select = styled.select`
  &&& {
    width: 100px;
    height: 34px;
  }
`;

type CentrexEditProps = {
  data: any;
  value: any;
};
const CentrexEdit = (props: CentrexEditProps) => {
  const { data, value } = props;
  const app = useApp();
  const snackbar = useSnackbarContext();
  const [toggle, setToggle] = useState(
    value.callManagement.type === 0 ? false : true
  );

  useEffect(() => {
    setToggle(value.callManagement.type === 0 ? false : true);
  }, [value.callManagement.type]);

  const [centrexList, setCentrexList] = useState([
    {
      id: '',
      accountId: '',
      accountPassword: '',
      carrier: 'KT',
    },
  ]);

  const centrexCallApi = useCallback(async () => {
    const defaultParams = {
      clinicId: data.id,
      perPage: 100,
    };
    const resp = await app.$clinicRepo.centrex.all(defaultParams);
    if (!resp) return;
    if (resp.data.length > 0) {
      setCentrexList(resp.data);
    } else {
      setCentrexList([
        {
          id: '',
          accountId: '',
          accountPassword: '',
          carrier: 'KT',
        },
      ]);
    }
  }, [app]);

  useEffect(() => {
    centrexCallApi();
  }, [centrexCallApi]);

  const addCentrexList = () => {
    setCentrexList([
      ...centrexList,
      {
        id: '',
        accountId: '',
        accountPassword: '',
        carrier: 'KT',
      },
    ]);
  };

  const onClickSaveCentrex = async (id: string, index: number) => {
    let object = centrexList[index];
    if (!object.carrier) {
      snackbar.alert('통신사를 선택해주세요.');
      return false;
    }
    try {
      const newParams = {
        ...object,
        clinicId: data.id,
      };
      if (id) {
        await app.$clinicRepo.centrex.update(objectToFormData(newParams), id);
      } else {
        await app.$clinicRepo.centrex.create(objectToFormData(newParams));
      }
      snackbar.success(`회선 등록 완료`);
      centrexCallApi();
    } catch (e: any) {
      snackbar.alert(e.description);
    }
  };

  const onClickDeleteCentrex = async (id: string) => {
    try {
      if (id) {
        await app.$clinicRepo.centrex.delete(id);
      }
      snackbar.success(`회선 삭제 완료`);
      centrexCallApi();
    } catch (e: any) {
      snackbar.alert(e.description);
    }
  };

  const onChange = (index: number, value: any, type: string) => {
    const newCentrexList = update(centrexList, {
      [index]: { [type]: { $set: value } },
    });
    setCentrexList(newCentrexList);
  };

  return (
    <Stack flexDirection={'column'}>
      <div className="grid-col">
        <div className="form-control required">
          <FlexRow className="label">
            <div style={{ width: '100px' }}>대행사</div>
            <div style={{ width: '200px' }}>ID</div>
            <div style={{ width: '200px' }}>PW</div>
          </FlexRow>
          {centrexList.map((v, i) => {
            return (
              <FlexRow key={i}>
                <div>
                  <Select
                    className="block"
                    value={v.carrier}
                    onChange={(e) => {
                      onChange(i, e.target.value, 'carrier');
                    }}
                  >
                    <option key={-1} value={''}>
                      -
                    </option>
                    <option key={1} value={'KT'}>
                      KT
                    </option>
                    <option key={2} value={'SK'}>
                      SK
                    </option>
                    <option key={3} value={'LGI'}>
                      LGI
                    </option>
                  </Select>
                </div>
                <div>
                  <input
                    style={{ width: '200px' }}
                    onChange={(e) => {
                      let { value } = e.target;
                      let centrexItems = [...centrexList];
                      centrexItems[i].accountId = value;
                      setCentrexList([...centrexItems]);
                    }}
                    value={v.accountId}
                    disabled={!toggle}
                  />
                </div>
                <div>
                  <input
                    style={{ width: '200px' }}
                    onChange={(e) => {
                      let { value } = e.target;
                      let centrexItems = [...centrexList];
                      centrexItems[i].accountPassword = value;
                      setCentrexList([...centrexItems]);
                    }}
                    value={v.accountPassword}
                    disabled={!toggle}
                  />
                </div>
                <div>
                  {v.id ? (
                    <FlexRow>
                      <Button
                        onClick={() => onClickDeleteCentrex(v.id)}
                        variant="outlined"
                        className="red-btn"
                        disabled={!toggle}
                      >
                        삭제
                      </Button>
                      <Button
                        variant="outlined"
                        className="primary-btn"
                        onClick={() => onClickSaveCentrex(v.id, i)}
                        disabled={!toggle}
                      >
                        수정
                      </Button>
                    </FlexRow>
                  ) : (
                    <Button
                      variant="outlined"
                      className="primary-btn"
                      onClick={() => onClickSaveCentrex('', i)}
                      disabled={!toggle}
                    >
                      등록
                    </Button>
                  )}
                </div>
              </FlexRow>
            );
          })}
        </div>
      </div>
      <Button
        variant="outlined"
        className="add-centrex-btn"
        onClick={addCentrexList}
        disabled={!toggle}
      >
        + 입력란 추가
      </Button>
    </Stack>
  );
};
export default observer(CentrexEdit);
