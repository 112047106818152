import { types } from 'mobx-state-tree';

const CertificationData = types.model({
  certId: types.optional(types.maybeNull(types.number)),
  certStatus: types.optional(types.maybeNull(types.string)),
  certCreatedAt: types.optional(types.string, ''),
  certEndDate: types.optional(types.maybeNull(types.string)),
  certSubjectDn: types.optional(types.maybeNull(types.string)),
  clinicId: types.optional(types.maybeNull(types.number)),
  clinicName: types.optional(types.maybeNull(types.string)),
  clinicMemo: types.optional(types.maybeNull(types.string)),
  userId: types.optional(types.maybeNull(types.number)),
  userEmail: types.optional(types.maybeNull(types.string)),
  userName: types.optional(types.maybeNull(types.string)),
  userStatus: types.optional(types.maybeNull(types.string)),
  userPhoneNumber: types.optional(types.maybeNull(types.string)),
});

const Data = types.model({
  certificationList: types.optional(types.array(CertificationData), []),
  total: types.optional(types.number, 0),
});

export const CertificationModel = types
  .model('certificationModel', {
    certificationsData: types.optional(Data, {}),
  })
  .views((self) => ({
    get certificationList() {
      return self.certificationsData.certificationList;
    },
    get total() {
      return self.certificationsData.total;
    },
  }))
  .actions((self) => ({
    setData(data) {
      if (!data) return;

      self.certificationsData.certificationList = data.data;
      self.certificationsData.total = data.total;
    },
  }));
